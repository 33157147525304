import styled from "styled-components";
import Box from "./Box";

const ArticleTypography = styled(Box)`
  p {
    margin-bottom: 1rem;
  }
  li,
  p {
    line-height: 1.7;
    font-size: 16px;
  }

  h2 {
    font-size: 2.2rem;
  }

  h2,
  h3,
  h4 {
    line-height: 1.15;
    margin-bottom: 0.5rem;
  }

  .gatsby-resp-image-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
`;

export default ArticleTypography;
